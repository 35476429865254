import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import TableComponent from '@/core/shared/table/table.component.vue';
import {container} from 'tsyringe';
import {store} from '@/store';
import {ImeiTariffServiceSandbox} from '@/pages/imei-tariff-service/sandbox/imei-tariff-service.sandbox';
import {IManufacturer} from '@/pages/imei-tariff-service/types/manufacturer';
import {IModelsManufacturer} from '@/pages/imei-tariff-service/types/models-manufacturer';
import DropdownManufacturerComponent
    from '@/pages/imei-tariff-service/components/dropdown-manufacturer/dropdown-manufacturer.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';

@Options({
    name: 'mobile-tariff-component',
    components: {
        TableComponent,
        DropdownManufacturerComponent,
        NotDataComponent
    }
})

export default class MobileTariffComponent extends Vue {
    private sandbox = container.resolve(ImeiTariffServiceSandbox);
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public manufacturer: string | null = null;
    public manufacturerId: number | null = null;
    public model: string | null = '';
    public dropdown = false;
    public pageNumber = 1;
    public pageSize = 10;
    public refreshPagination = false;
    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };

    public determineNumber(index: number) {
        if (this.pageNumber === 1) {
            return index + 1;
        } else if (this.pageNumber > 1) {
            return (((this.pageNumber - 1) * this.pageSize) + (index + 1));
        }
    }


    get manufacturers(): IManufacturer[] {
        return this.store.getters._getManufacture;
    }

    get modelsByManufacturer(): IModelsManufacturer[] {
        return this.store.getters._getModelsByManufacturer;
    }

    get modelsByManufacturerTotalCount(): number {
        return this.store.getters._getModelsByManufacturerTotalCount;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.imeiTariff) {
                this.locale = res.imeiTariff;
            }
        });
        this.sandbox.getManufacture();
    }

    public changeManufacturerInput(): void {
        this.manufacturer = '';
    }

    public resetPagination() {
        if (this.pageNumber > 1) {
            this.$refs.pagination.reset();
            this.pageNumber = 1;
        }
    }

    public manufacturerClick(info: IManufacturer) {
        this.model = '';
        this.manufacturer = info.name;
        this.manufacturerId = info.id;
        this.dropdown = false;
        this.resetPagination();
        this.sandbox.getModelsByManufacturer({
            token: localStorage.getItem('mainToken'),
            modelId: this.manufacturerId,
            word: this.model.trim(),
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
        });
        this.refreshPagination = true;
    }

    public getModel() {
        if (this.manufacturer) {
            this.resetPagination();
            this.sandbox.getModelsByManufacturer({
                modelId: this.manufacturerId,
                word: this.model?.trim(),
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
            });
        }
    }

    public onClickOutside(info: boolean): void {
        this.dropdown = info;
    }

    public changePage(info: any) {
        this.pageNumber = info.pageNumber;
        this.pageSize = info.pageSize;
        this.sandbox.getModelsByManufacturer({
            modelId: this.manufacturerId,
            word: this.model,
            pageSize: info.pageSize,
            pageNumber: info.pageNumber,
        });
        window.scroll(0, this.$el.querySelector('#table').offsetTop);
    }

    public beforeUnmount() {
        this.store.commit('SET_RESET_MODELS_BY_MANUFACTURER');
    }

}
