<style src="./mobile-market-tariff.component.scss" lang="scss"></style>

<template>
  <div class="mobile-market-tariff">
    <div class="row">
      <div class="col-12">
        <table-component>
          <thead>
          <tr>
            <th>{{ locale.marketPriceMobile }}</th>
            <th class="text-center">{{ locale.price }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in marketTariffs" :key="item.id">
            <th>{{ item.description }}</th>
            <th class="text-center">{{ item.price }} AZN</th>
          </tr>
          </tbody>
        </table-component>
      </div>
      <div class="col-12">
        <ul class="mobile-market-tariff__info mt-20">
          <li v-for="item in marketTariffsInfo">
            <span>
              {{ item.title }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script src="./mobile-market-tariff.component.ts"></script>
