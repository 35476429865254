<style src="./numbers-transferred-service.component.scss" lang="scss"></style>

<template>
  <div class="numbers-transferred-service mb-100">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
          <section class="mt-30 mb-100">
            <v-card class="h-container">
              <number-checked-component/>
            </v-card>
          </section>

          </div>
      </div>
    </div>
  </div>
</template>

<script src="./numbers-transferred-service.component.ts"></script>
