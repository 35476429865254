import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {container} from 'tsyringe';
import {
    NumbersTransferredServiceSandbox
} from '@/pages/numbers-transferred-service/sandbox/numbers-transferred-service.sandbox';
import {Login} from '@/core/configs/api';

@Options({
    name: 'number-checked-component',
    components: {
        ServiceNameComponent
    }
})

export default class NumberCheckedComponent extends Vue {
    public service = 1069;
    private sandbox = container.resolve(NumbersTransferredServiceSandbox);
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public prefix = '55';
    public number = '';
    public errorNumber = false;

    get prefixes() {
        return [
            {
                id: '50',
                value: '050'
            },
            {
                id: '51',
                value: '051'
            },
            {
                id: '10',
                value: '010'
            },
            {
                id: '55',
                value: '055'
            },
            {
                id: '99',
                value: '099'
            },
            {
                id: '70',
                value: '070'
            },
            {
                id: '77',
                value: '077'
            },
            {
                id: '66',
                value: '066'
            },
        ];
    }

    get status() {
        return this.store.getters._getMobileMNPCheck;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.searchOfficesClassifier) {
                this.locale = res.searchOfficesClassifier;
            }
        });
        this.status.isPortability = null;
    }

    public validation() {
        this.number = this.number.replace(/[^0-9]+/g, '');
        if (this.number.length === 7) {
            this.errorNumber = false;
        } else {
            this.errorNumber = true;
        }
    }

    public selectPrefix(info: any) {
        this.prefix = info;

    }

    public sendData() {
        this.validation();
        if (!this.errorNumber) {
            let number = `${this.prefix}${this.number.replace(/[^+\d]/g, '')}`;
            this.sandbox.getMobileMNPCheck(number);
        }
    }


}
