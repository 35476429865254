import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class NumbersTransferredServiceSandbox {
    private store = store;

    constructor() {
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

    getMobileMNPCheck(payload: string): void {
        this.store.dispatch('getMobileMNPCheck', payload);
    }

}
