import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';
import MobileTariffComponent from '@/pages/imei-tariff-service/components/mobile-tariff/mobile-tariff.component.vue';
import MobileMarketTariffComponent
    from '@/pages/imei-tariff-service/components/mobile-market-tariff/mobile-market-tariff.component.vue';
import {container} from 'tsyringe';
import {ImeiTariffServiceSandbox} from '@/pages/imei-tariff-service/sandbox/imei-tariff-service.sandbox';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'imei-tariff-service-component',
    components: {
        ServiceNameComponent,
        ServiceSubtitleComponent,
        MobileTariffComponent,
        MobileMarketTariffComponent
    }
})

export default class ImeiTariffServiceComponent extends mixins(Router)  {
    public service = 1069;
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox)
    private sandbox = container.resolve(ImeiTariffServiceSandbox);
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public viewMobile = 'mobileTariff';

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.imeiTariff) {
                this.locale = res.imeiTariff;
            }
        });
    }

    public mounted() {
        this.getServiceData();
    }

}
