import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {container} from 'tsyringe';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {
    NumbersTransferredServiceSandbox
} from '@/pages/numbers-transferred-service/sandbox/numbers-transferred-service.sandbox';
import NumberCheckedComponent
    from '@/pages/numbers-transferred-service/components/number-checked/number-checked.component.vue';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'numbers-transferred-service',
    components: {
        ServiceNameComponent,
        NumberCheckedComponent
    }
})

export default class NumbersTransferredServiceComponent extends mixins(Router) {
    public service = 1094;
    private sandbox = container.resolve(NumbersTransferredServiceSandbox);
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public viewMobile = 'mobileTariff';

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.searchOfficesClassifier) {
                this.locale = res.searchOfficesClassifier;
            }
        });
    }

    public mounted() {
        this.getServiceData();
    }


}
