<style src="./number-checked.component.scss" lang="scss"></style>

<template>
  <div class="number-checked">
    <form action="" @submit.prevent="sendData">
      <div class="number-checked__wrapper">
        <div class="row">
          <div class="col-12 d-lg-block d-none"><label class="v-label">Mobil nömrə</label></div>
          <div class="col-12 d-lg-none d-block"><label class="v-label">Prefiks</label></div>
          <div class="col-lg-2 col-12">
            <v-select
                ref="selectPrefix"
                @select="selectPrefix"
                placeholder="055"
                :options="prefixes"
                :value="prefix"
            />
          </div>
          <div class="col-12 d-lg-none d-block mt-24"><label class="v-label">Mobil nömrə</label></div>
          <div class="col-lg-3 col-12">
            <div class="form__group">
              <input
                  :class="{'v-input--error':errorNumber}"
                  v-model="number"
                  placeholder="Nömrənizi  daxil edin"
                  v-mask="['###-##-##', '###-##-##']" type="text" class="v-input">
            </div>
          </div>
          <div class="col-12 d-lg-none d-block">
            <v-divider class=" my-30"/>
          </div>
          <div class="col-lg-2 col-12 d-sm-flex d-md-block justify-center pl-0">
            <v-btn class="form__btn" color="dark">Yoxla</v-btn>
          </div>
        </div>
      </div>
    </form>
    <v-card class="mt-40 px-20 py-40" dark v-if="status.isPortability===false && status.isPortability!==null">
      Cari nömrə üçün mobil nömrə daşınma xidmətindən istifadə edilməmişdir.
    </v-card>
    <v-card class="mt-40 px-20 py-40" dark v-if="status.isPortability===true && status.isPortability!==null">
      Cari nömrə <span class="mobileCheck">{{ status.operatorName }}</span> mobil daşınma xidmətindən istifadə edir.
    </v-card>
  </div>
</template>

<script src="./number-checked.component.ts"></script>
