<style src="./imei-tariff-service.component.scss" lang="scss"></style>

<template>

  <div class="imei-tariff-service mb-100">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-12">
          <section class="mt-20">
            <v-card class="h-container">
<!--              <services-subtitle-component>-->
<!--                Xidməti seçin-->
<!--              </services-subtitle-component>-->
              <div class="imei-tariff-service__input-wrapper ">
                <div class="imei-tariff-service__radio">
                  <input
                      v-model="viewMobile"
                      type="radio" class="v-radio v-radio--circle v-radio--dark-blue"
                      value="mobileTariff" id="mobileTariff">
                  <label for="mobileTariff" class="v-label">{{ locale.mobileTariff }}</label>
                </div>
                <div class="imei-tariff-service__radio ml-44">
                  <input
                      v-model="viewMobile"
                      type="radio" class="v-radio v-radio--circle v-radio--dark-blue"
                      value="mobileMarketTariff" id="mobileMarketTariff">
                  <label for="mobileMarketTariff" class="v-label">{{ locale.mobileMarketTariff }}</label>
                </div>
              </div>
              <KeepAlive>
                  <mobile-tariff-component class="mt-40" v-if="viewMobile === 'mobileTariff'"/>
                  <mobile-market-tariff-component class="mt-40" v-else/>
              </KeepAlive>
            </v-card>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./imei-tariff-service.component.ts"></script>
