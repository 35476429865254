<style src="./mobile-tariff.component.scss" lang="scss"></style>

<template>
  <div class="mobile-tariff">
    <div class="mobile-tariff__input-wrapper">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="mobile-tariff__input-group">
            <label for="manufacturerName" class="v-label  mb-4">{{ locale.manufacturerName }}</label>
            <input
                v-model="manufacturer"
                @keydown.delete.prevent
                @focus="dropdown = true"
                @input="changeManufacturerInput"
                :placeholder="locale.enterTheManufacturerName"
                type="text" id="manufacturerName" class="v-input">
            <img
                @click="dropdown = !dropdown"
                :class="{'select-active':dropdown}"
                src="@/assets/images/icons/select-down.svg" class="select">
            <transition name="fade-opacity">
              <dropdown-manufacturer-component
                  @onClickOutside="onClickOutside"
                  v-if="dropdown"
                  @manufacturerClick="manufacturerClick"
                  :manufacturer="manufacturers"/>
            </transition>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="mobile-tariff__input-group">
            <label for="modelName" class="v-label  mb-4">{{ locale.modelName }}</label>
            <input
                @change="getModel"
                v-model="model"
                :placeholder="locale.enterTheModelName"
                type="text" id="modelName" class="v-input">
          </div>
        </div>
        <div class="col-12" v-if="modelsByManufacturer.length>0">
          <table-component class="mt-40" id="table">
            <thead>
            <tr>
              <th>{{ locale.queue }}</th>
              <th>{{ locale.manufacturer }}</th>
              <th class="text-left">{{ locale.model }}</th>
              <th>{{ locale.simCount }}</th>
              <th>{{ locale.price }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in modelsByManufacturer" :key="index">
              <th>{{ determineNumber(index) }}</th>
              <th>{{ item.name }}</th>
              <th class="text-left">{{ item.model }}</th>
              <th>{{ item.cardTypeId }}</th>
              <th>{{ item.tariff }} AZN</th>
            </tr>
            </tbody>
          </table-component>
            <v-pagination
                ref="pagination"
                :limit="pageSize"
                :dataCount="modelsByManufacturerTotalCount"
                :page="pageNumber"
                @changePage="changePage"
                class="mt-40 mb-100  "/>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./mobile-tariff.component.ts"></script>
