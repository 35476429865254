import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import TableComponent from '@/core/shared/table/table.component.vue';
import {IMarketTariff} from '@/pages/imei-tariff-service/types/market-tariff';
import {IMarketTariffsInfo} from '@/pages/imei-tariff-service/types/market-tariffs-info';

@Options({
    name: 'mobile-market-tariff-component',
    components: {
        TableComponent,
    }
})

export default class MobileMarketTariffComponent extends Vue {
    public locale$: any = locale;
    private store = store;
    public locale: any = null;

    get marketTariffs(): IMarketTariff[] {
        return this.store.getters._getMarketTariffs;
    }

    get marketTariffsInfo(): IMarketTariffsInfo[] {
        return this.store.getters._getMarketTariffsInfo;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.imeiTariff) {
                this.locale = res.imeiTariff;
            }
        });
    }


}
